import React, { useState } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

// Components
import { LinkMainInt } from "../Base/links/LinkMain";
import Socialicons from "../Base/socials/SocialIcons";
import Logo from "../Base/Logo";

// Config
import { colors, socials, mediaQueries } from "../../configs/_configSite";

const LogoWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 30px;
  @media (max-width: ${mediaQueries.xxs}px) {
    top: 12px;
    left: 20px;
  }
`;

const LinksWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 60px;
  transition: 0.25s;
  opacity: 1;
  @media (max-width: ${mediaQueries.lg}px) {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.gold};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
  }
`;

const ActionsWrapper = styled.div`
  position: absolute;
  z-index: 100;
  top: 24px;
  right: 30px;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-row-gap: 20px;
  text-align: center;
  justify-items: center;
  transition: 0.25s;

  @media (max-width: ${mediaQueries.lg}px) {
    top: 55px;
    opacity: 1;
  }
`;

const Toggle = styled.button`
  background: none;
  outline: none;
  border: none;
  color: ${colors.gold};

  font-size: 2rem;
  line-height: 0.6em;
  padding: 0;
  margin: -4px 0 0 2px;
  transition: 0.25s;
  position: absolute;
  top: 20px;
  right: 30px;
  z-index: 100;

  :before {
    content: "\\d7";
    display: block;
  }
  @media (min-width: ${mediaQueries.lg}px) {
    display: none;
  }
`;

const LinkMainIntEdited = styled(LinkMainInt)`
  display: inline-table;
  margin-top: 5px;
  margin-right: 20px;
  text-transform: capitalize;

  @media (max-width: ${mediaQueries.lg}px) {
    display: inline-block;
    color: ${colors.white};

    font-size: 1.25rem;
    margin: 10px 0;
  }
`;

const DDContainer = styled.div`
  display: flex;
  position: absolute;
  max-height: 0px;
  left: -8px;
  background-color: #f1f1f1;
  min-width: 170px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  flex-direction: column;
  transition: max-height 0.2s ease-in-out;
  z-index: 1;
  overflow: hidden;
  border-radius: 5px;

  ${LinkMainIntEdited} {
    padding: 5px 10px;
  }

  @media (max-width: ${mediaQueries.lg}px) {
    position: relative;
    min-width: initial;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
    left: 0;

    a {
      color: ${colors.gold} !important;
    }

    a:hover {
      opacity: 0.75;
    }
  }
`;

const DDHover = styled.div`
  position: relative;
  display: inline-table;
  margin-top: 5px;
  margin-right: 20px;
  text-transform: capitalize;

  margin-right: 0;

  &:hover {
    ${DDContainer} {
      max-height: 120px;
    }
    svg {
      transform: rotate(0deg);
    }
  }

  @media (max-width: ${mediaQueries.lg}px) {
    width: 100%;
    text-align: center;
    margin-right: 0;
  }
`;

const CarrotArrow = () => (
  <svg
    width="15"
    height="10"
    viewBox="0 0 36 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    css={css`
      top: 0;
      margin-left: 15px;
      right: 0;
      bottom: 0;
      transition: 0.3s;
      transform: rotate(-90deg);

      @media (min-width: ${mediaQueries.lg}px) {
        display: none;
      }
    `}
  >
    <path
      d="M18 30L0.679492 5.52035e-07L35.3205 3.58045e-06L18 30Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default () => {
  const [showLinks, setShowLinks] = useState(false);
  return (
    <>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>

      <LinksWrapper
        css={css`
          @media (max-width: ${mediaQueries.lg}px) {
            ${!showLinks && "opacity: 0; pointer-events: none;"}
          }
        `}
      >
        <DDHover>
          <LinkMainIntEdited to="/about">
            <span className="nav-item-about">About</span>
            <CarrotArrow />
          </LinkMainIntEdited>
          <DDContainer>
            <LinkMainIntEdited to="/about/know">
              <span className="nav-item-know">Know</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/about/believe">
              <span className="nav-item-believe">Believe</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/about/fulfill">
              <span className="nav-item-believe">Fulfill</span>
            </LinkMainIntEdited>
          </DDContainer>
        </DDHover>

        <DDHover>
          <LinkMainIntEdited to="/beautiful-lifestyles">
            <span className="nav-item-beautiful-lifestyles">
              Beautiful Lifestyles
            </span>
            <CarrotArrow />
          </LinkMainIntEdited>
          <DDContainer>
            <LinkMainIntEdited to="/beautiful-lifestyles/crossbody-bag">
              <span className="nav-item-crossbody-bag">Crossbody Bag</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/beautiful-lifestyles/t-shirts">
              <span className="nav-item-t-shirts">T-Shirts</span>
            </LinkMainIntEdited>
          </DDContainer>
        </DDHover>

        <DDHover>
          <LinkMainIntEdited to="/beautiful-vestiges">
            <span className="nav-item-beautiful-vestiges">
              Beautiful Vestiges
            </span>
            <CarrotArrow />
          </LinkMainIntEdited>
          <DDContainer>
            <LinkMainIntEdited to="/beautiful-vestiges/triple-negative">
              <span className="nav-item-triple-negative">Triple Negative</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/beautiful-vestiges/donate">
              <span className="nav-item-donate">Donate</span>
            </LinkMainIntEdited>
          </DDContainer>
        </DDHover>

        <DDHover>
          <LinkMainIntEdited to="/royal-ambassadors">
            <span className="nav-item-royal-ambassadors">
              Royal Ambassadors
            </span>
            <CarrotArrow />
          </LinkMainIntEdited>
          <DDContainer>
            <LinkMainIntEdited to="/royal-ambassadors/journal">
              <span className="nav-item-journal">Journal</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/royal-ambassadors/notecards">
              <span className="nav-item-notecards">Notecards</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/royal-ambassadors/music-player">
              <span className="nav-item-music-player">Music Player</span>
            </LinkMainIntEdited>
          </DDContainer>
        </DDHover>

        <DDHover>
          <LinkMainIntEdited to="/the-great-commission">
            <span className="nav-item-the-great-commission">
              The Great Commission
            </span>
            <CarrotArrow />
          </LinkMainIntEdited>
          <DDContainer>
            <LinkMainIntEdited to="/the-great-commission/wayuu-missions">
              <span className="nav-item-wayuu-missions">Wayuu Missions</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/the-great-commission/reliance-church">
              <span className="nav-item-reliance-church">Reliance Church</span>
            </LinkMainIntEdited>
            <LinkMainIntEdited to="/the-great-commission/capilla-fidelidad">
              <span className="nav-item-capilla-fidelidad">
                Capilla Fidelidad
              </span>
            </LinkMainIntEdited>
          </DDContainer>
        </DDHover>

        <LinkMainIntEdited to="/contact">
          <span className="nav-item-contact">Contact</span>
        </LinkMainIntEdited>
      </LinksWrapper>

      <Toggle
        type="button"
        onClick={() => setShowLinks(!showLinks)}
        css={css`
          ${!showLinks && "transform: rotate(45deg);"}
          ${showLinks && "color: #fff; z-index: 99999999;"}
        `}
      />
      <ActionsWrapper
        css={css`
          @media (max-width: ${mediaQueries.lg}px) {
            ${!showLinks && "right: -50px; opacity: 0;"}
          }
        `}
      >
        <Socialicons
          href={socials.facebook}
          color={colors.gold}
          colorHover={colors.white}
          social="facebook"
        />
        <Socialicons
          href={socials.instagram}
          color={colors.gold}
          colorHover={colors.white}
          social="instagram"
        />
      </ActionsWrapper>
    </>
  );
};
